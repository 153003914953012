.container {
  cursor: pointer;
  display: flex;
  padding: 12px 16px;
  user-select: none;
}

.avatar {
  margin-right: 12px;
}

.username {
  font-weight: bold;
  font-size: 1.05rem;
  margin-bottom: 3px;
}

.userRole {
  color: gray;
}
