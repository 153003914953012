.container {
  background: #eee;
  /* min-height: 100%; */
  /* max-height: 100vh; */
}

.innerContainer {
  max-width: 420px;
  margin: 0 auto;
  background: white;
}

@media screen and (max-width: 1024px) and (min-width: 729px) {
  .container {
    overflow-y: hidden;
  }
}
