.modalShadow {
  background: rgba(17, 17, 17, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.modal {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  overflow: hidden;

  width: 500px;
  padding: 24px;
  text-align: center;
}

.mobileModal {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
}

.mobileModalFooter {
  display: block;
}

.mobileModalFooter button{
  width: 100%;
  margin-bottom: 9.5px;
  margin-top: 9.5px;
  padding-top: 11px;
  padding-bottom: 11px;
}

.alert {
  border-top: 20px solid #ea3636;
}

.offline {
  border-top: 20px solid #82686b;
}

.modalIcon {
  margin-bottom: 24px;
}

.modalTitle {
  font-size: 1.6rem;
  margin-bottom: 12px;
}

.modalDescription {
  font-size: 1.2rem;
  color: #3f4254;
}

.modalFooter {
  margin-top: 24px;
}
