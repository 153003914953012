.deviceNode {
  width: 32px;
  height: 32px;
  object-fit: contain;
}

.flickerNode {
  position: absolute;
  animation: flickerAnimation 3s ease-in-out infinite;
  -webkit-animation: flickerAnimation 3s ease-in-out infinite;
  -moz-animation: flickerAnimation 3s ease-in-out infinite;
  -o-animation: flickerAnimation 3s ease-in-out infinite;
}

.activeDeviceNode {
  width: 42px;
  height: 42px;
  object-fit: contain;
}

.container {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.activeContainer {
  opacity: 1;
  z-index: 100;
}

.inactiveContainer {
  opacity: 0.6;
}

.deviceLightHolder {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-32%, -34%);
  overflow: hidden;
  background: white;
}

.deviceLightHolderActive {
  composes: deviceLightHolder;
  width: 14px;
  height: 14px;
  transform: translate(-32%, -34%);
}

.deviceLight {
  width: 100%;
  height: 100%;
  display: block;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.45) inset;
  border-radius: 100%;
}

.deviceLightNormal {
  composes: deviceLight;
  background-color: #fee38e;
  /* box-shadow: none; */
  /* border: none; */
  position: relative;
}

.deviceLightNormal::after {
  content: " ";
  height: 100%;
  width: 100%;
  background: white;
  position: absolute;
  /* animation: lightFlashingNormal 4s ease-in-out infinite; */
  box-shadow: none;
}

.deviceLightAlarm {
  composes: deviceLight;
  /* animation: lightFlashingAlarm 4s ease-in-out infinite; */
}

.deviceLightDisconnected {
  composes: deviceLight;
}

.offline {
  transform: scale(1.14);
}

@keyframes lightFlashingAlarm {
  0% {
    background-color: red;
  }

  50% {
    background-color: white;
  }

  100% {
    background-color: red;
  }
}

@keyframes lightFlashingNormal {
  0% {
    background-color: white;
  }

  50% {
    background-color: #fee38e;
  }

  100% {
    background-color: white;
  }
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
