.profile {
  padding: 16px 16px;
  color: #1e1e2d;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  border-bottom: 1px solid #DADADA;
}

.avatar {
  object-fit: cover;
  border-radius: 46px;
  height: 46px;
  width: 46px;
  margin-right: 12px;
  border: 1px solid #ccc;
}

.emptyAvatar {
  composes: avatar;
  border: 1px solid #dadada;
  padding: 6px;
}

.username {
  flex: 1;
  font-weight: bold;
  margin-bottom: 2px;
}

.email {
  font-size: 0.8rem;
  color: #9c9c9c;
}