.container {
  padding: 14px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  z-index: 999;
  background: white;
  position: fixed;
  width: 100%;
  top: 0;
  left: 50%;
  max-width: 420px;
  transform: translateX(-50%);
}

.title {
  font-weight: bold;
  font-size: 1rem;
  flex: 1
}
