.container {
  border-bottom: 1px solid #dadada;
  height: var(--page-header-height);
  display: flex;
  align-items: center;
  padding: 0 24px;
  background: var(--page-breadcrumbs-background);
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 10%);
}

.title {
  font-size: 1.4rem;
  margin-right: 24px;
}
