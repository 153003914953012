.container {
  position: absolute;
  background: white;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 16px;
  padding: 32px;
  width: 1000px;
}

.header {
  margin-bottom: 16px;
}

.title {
  font-size: 1.5rem;
}

.subtile {
  font-size: 1rem;
  margin-top: 10px;
}

.subHeader {
  margin-bottom: 20px;
}

.label {
  font-weight: bold;
  margin-right: 6px;
}

@media (max-width: 1024px) {
  .container {
    width: 96%;
    margin: 0 auto;
    padding: 16px;
  }
}
