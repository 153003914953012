.container {
  display: block;
  background: var(--page-background);
  height: 100dvh;
  display: flex;
  flex-direction: column;
}

.content {
  width: 100%;
  flex: 1;
  overflow-y: auto;
}

.header {
  border-bottom: 1px solid #dadada;
  height: 72px;
  display: flex;
  align-items: center;
  padding: 0 24px;
  background: var(--page-breadcrumbs-background);
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 10%);
}
