.container {
  position: fixed;
  bottom: 180px;
  right: 12px;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 24px;
}

.compassPointer {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.globeIcon {
  width: 20px;
  height: 20px;
  object-fit: contain;
}
