.languageSwitcher {
  position: relative;
  z-index: 10;
  min-width: 10rem;
}

.currentLanguage {
  padding: 20px;
  cursor: pointer;
}

.wrapper {
  display: flex;
  align-items: center;
}

.wrapper img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.wrapper span {
  margin-left: 12px;
  font-weight: bold;
  color: rgb(246, 78, 96);
  font-size: 13px;
}

.dropdown {
  display: none;
  width: 100%;
  position: absolute;
  inset: 0px auto auto 0px;
  margin: 0px;
  transform: translate3d(0px, 65px, 0px);
  font-size: 13px;
  color: #3f4254;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.15);
  min-width: 10rem;
  border-radius: 0.42rem;
  box-shadow: 0px 0px 50px 0px rgb(82 63 105 / 15%);
}

.active {
  display: block;
}

.list {
  padding: 13px 0;
}

.list li {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
}

.list li:hover {
  background-color: #f3f6f9;
  transition: all 0.15s ease;
  color: #3699ff;
}

.list li img {
  width: 20px;
  height: 20px;
  border-radius: 0.42rem;
  margin-right: 25px;
}
