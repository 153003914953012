.container {
  padding: 14px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  z-index: 999;
  background: white;
  position: fixed;
  width: 100%;
  top: 0;
  left: 50%;
  max-width: 420px;
  transform: translateX(-50%);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.title {
  font-weight: bold;
  font-size: 1rem;
  flex: 1;
}

.searchBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.searchInput {
  width: 100%;
  border: none;
  font-size: 1rem !important;
  background: #f0f0f0;
  padding: 10px 20px;
  border-radius: 99px;
  outline: none;
}

.searchInput::placeholder {
  color: #9c9c9c;
}
