.container {
  border-bottom: 1px solid #dadada;
  height: var(--page-header-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  background: var(--page-header-background);
}

.pageTitle {
  font-size: 1.4rem;
  font-weight: bold;
  margin-left: 16px;
}

.logo img {
  width: 100px;
  object-fit: contain;
}
